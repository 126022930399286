<script setup lang="ts">
import utils from './utils'
import { jumpToRefidUrl } from '~/utils/refid'
import { useTrainStore } from '~/store/train'

const { t } = useLang()
const { query } = useRoute()
const trainStore = useTrainStore()

interface CityItem {
  nameX: string
  nameCn: string
  showStr?: string
  cityId?: string
}
interface SateType {
  [Key: string]: any
}
enum Entrance {
  From = 1,
  To = 2,
}

const today = dayjs().formatDate()

const refPopularCity = ref(null)
const refTrainCalendar = ref(null)
const refToCity = ref(null)

const entranceType = ref(0)
const trainHistoryCity = ref<any[]>([])

const state: SateType = reactive({
  trainStartCity: '', // 出发城市
  trainEndCity: '', // 到达城市
  fromInfo: '', // 展示出发数据
  toInfo: '', // 展示到达数据
  inputValue: '', // 用户输入

  trainGoDate: '',
  startDate: '',

  isHighSpeed: false,

  isFromLine: false,
  isToLine: false,
  QerrorMsg: '', // 城市提醒
  DerrorMsg: '', // 日期提醒
  isAnimate: false,
  isGoDateLine: false,

  isMask: false, // 蒙层
  festivals: {}, // 节日数据

  b_train_from: t('home.b_train_from'),
  b_train_to: t('home.b_train_to'),
})

const disabledBtn = computed(() => {
  return state.QerrorMsg || state.DerrorMsg
})

function getCityRef(): any {
  return refPopularCity.value || {}
}
function getCalendarRef(): any {
  return refTrainCalendar.value || {}
}
function getToCityRef(): any {
  return refToCity.value || {}
}

// 城市方法
const cityFunc = {
  timer: null as any,
  searchTimer: null as any,
  focus(type: number, event: any) {
    closeAll()
    entranceType.value = type

    state.isFromLine = false
    state.isToLine = false
    state.QerrorMsg = ''

    const refEl: any = getCityRef()
    refEl.popularCity.open(type, type === Entrance.From ? state.trainStartCity : state.trainEndCity)

    let submitData = null
    switch (type) {
      case Entrance.From:
        state.isFromLine = true
        if (state.fromInfo && !state.trainStartCity) {
          submitData = state.fromInfo
        }

        break
      case Entrance.To:
        state.isToLine = true
        if (state.toInfo && !state.trainEndCity) {
          submitData = state.toInfo
        }

        break
      default:
        break
    }
    submitData && refEl.searchCity?.searchSubmit(type, submitData)

    if (event) {
      // 光标定位要加上 setTimeOut，不然就会重新光标定位失败
      const obj = event.srcElement
      const len = obj.value.length
      setTimeout(() => {
        obj.selectionStart = obj.selectionEnd = len
      }, 60)
    }
  },
  click(type: number, event: any) {
    if (!state.isFromLine && !state.isToLine) {
      this.focus(type, event)
    }
  },
  input(type: number, event: any) {
    clearTimeout(this.searchTimer)
    if (type === Entrance.To) {
      state.trainEndCity = ''
    }
    else {
      state.trainStartCity = ''
    }

    state.inputValue = event.currentTarget.value || ''
    this.searchTimer = setTimeout(() => {
      const refEl: any = getCityRef()
      if (state.inputValue.length) {
        refEl.searchCity?.searchSubmit(type, state.inputValue)
      }
      else {
        refEl.popularCity?.open(type, type === Entrance.From ? state.trainStartCity : state.trainEndCity)
      }
    }, 250)
  },
  blur(type: number, event: any) {
    switch (type) {
      case Entrance.From:
        state.isFromLine = false
        if (!state.trainStartCity) {
          state.fromInfo = ''
        }
        break
      case Entrance.To:
        state.isToLine = false
        if (!state.trainEndCity) {
          state.toInfo = ''
        }
        break
      default:
        break
    }
  },
  handleSelectHistory(info: any) {
    this.fillHistoryInfo(info)
  },
  handleRemoveHistory(index: number) {
    trainHistoryCity.value.splice(index, 1)
  },
  // 设置出发和到达城市
  setTrainStartCity(city: CityItem, focusEndCity = true) {
    state.trainStartCity = city
    state.fromInfo = this.showName(city)

    utils.storage.fromStation = city
    utils.setStorage()

    if (focusEndCity) {
      nextTick(() => {
        getToCityRef()?.focus()
      })
    }
  },
  setTrainEndCity(city: CityItem) {
    state.trainEndCity = city
    state.toInfo = this.showName(city)

    utils.storage.toStation = city
    utils.setStorage()
  },
  // 出发到达城市互换
  cityChange() {
    state.isAnimate = true
    this.timer && clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      clearTimeout(this.timer)
      const { trainStartCity, trainEndCity } = state
      state.isAnimate = false

      if (trainStartCity && trainEndCity) {
        this.setTrainStartCity(trainEndCity, false)
        this.setTrainEndCity(trainStartCity)
      }
      else {
        const { fromStation, toStation } = utils.storage
        this.setTrainStartCity(toStation, false)
        this.setTrainEndCity(fromStation)
      }
      closeAll()
    }, 300)
  },
  showName(city: CityItem) {
    if (city?.nameX && city?.nameCn) {
      return `${city.nameX} (${city.nameCn})`
    }

    return ''
  },
  fillHistoryInfo(info: any) {
    state.trainStartCity = info.fromStation || ''
    state.fromInfo = this.showName(info.fromStation)

    state.trainEndCity = info.toStation || ''
    state.toInfo = this.showName(info.toStation)

    this.judgeSameStation()
    state.isMask = false
  },
  // 存历史搜索
  setTrainHistoryCity() {
    let list = utils.storage.trainHistoryCity || []
    // 去重
    for (let i = 0; i < list.length; i++) {
      for (let j = i + 1; j < list.length; j++) {
        if (list[i].fromStation.nameX === list[j].fromStation.nameX && list[i].toStation.nameX === list[j].toStation.nameX) {
          list.splice(j, 1)
          j--
        }
      }
    }
    // 取前3条
    if (list.length > 3) {
      list = list.slice(0, 3)
    }
    utils.storage.trainHistoryCity = list
    trainHistoryCity.value = [...list]
    utils.setStorage()
  },
  // 判断出发站和到达站是否相同
  judgeSameStation() {
    if (!state.fromInfo) {
      state.QerrorMsg = t('home.f_train_depart_empty')
    }
    else if (!state.toInfo) {
      state.QerrorMsg = t('home.f_train_arrival_empty')
    }
    else if (state.fromInfo && state.toInfo && state.fromInfo === state.toInfo) {
      state.QerrorMsg = t('home.f_train_isdiff')
    }
    else {
      state.QerrorMsg = ''
    }
  },
}

function closeAll() {
  const calendarEl = getCalendarRef()
  state.isGoDateLine = false
  calendarEl?.close && calendarEl?.close()

  cityFunc.judgeSameStation()
  const refEl: any = getCityRef()
  refEl?.methods?.close && refEl?.methods?.close()
  state.isFromLine = false
  state.isToLine = false
}

// 日期方法
const dateFunc = {
  // 设置机票出发日期
  setTrainGoDate(date: any = '', store = true) {
    state.trainGoDate = utils.getFormatDate(date)

    switch (utils.curLang) {
      case 'zh-hk':
      case 'zh-tw':
      case 'ja-jp':
        state.startDate = `${state.trainGoDate.date[1]}月${state.trainGoDate.date[2]}日 ${state.trainGoDate.week}`
        break
      default:
        state.startDate = `${state.trainGoDate.week}, ${state.trainGoDate.enMonth} ${state.trainGoDate.date[2]}`
        break
    }

    state.isGoDateLine = false

    if (store) {
      utils.storage.trainGoDate = state.trainGoDate.day || ''
      utils.setStorage()
    }
  },
  clickCalendar() {
    closeAll()

    state.DerrorMsg = ''
    state.isGoDateLine = true

    const calendarEl = getCalendarRef()
    calendarEl?.show(state.trainGoDate?.day)
  },
  checkDateIsInvalid(storageDate: string) {
    if (!storageDate) {
      return true
    }

    const date = dayjs(storageDate)
    return date.isBefore(today)
  },
}

// 确认选择城市
function emitConfirmCity(city: CityItem) {
  if (entranceType.value === Entrance.From) {
    cityFunc.setTrainStartCity(city)
    $utils.sendEvent('', '', '火车票主搜', `^城市^模块:出发城市^城市ID:${city.cityId}^城市名称:${city.nameCn}^`)
  }
  else {
    cityFunc.setTrainEndCity(city)
    $utils.sendEvent('', '', '火车票主搜', `^城市^模块:到达城市^城市ID:${city.cityId}^城市名称:${city.nameCn}^`)
  }

  closeAll()
}
// 确认日期
function emitConfirmDate(date: any) {
  dateFunc.setTrainGoDate(date)
  $utils.sendEvent('', '', '火车票主搜', '^日期选择^')
  closeAll()
}

// 切换高铁
function switchHighSpeed() {
  state.isHighSpeed = !state.isHighSpeed

  $utils.sendEvent('', '', '火车票主搜', `^${state.isHighSpeed ? '勾选仅限高铁' : '取消勾选仅限高铁'}^`)
}
// 点击搜索
function handleSearch() {
  cityFunc.judgeSameStation()

  if (disabledBtn.value) {
    return
  }

  const fromTo = {
    fromStation: state.trainStartCity,
    toStation: state.trainEndCity,
  }
  trainHistoryCity.value.unshift(fromTo)

  const queryParam = {
    depStationName: state.trainStartCity.nameCn, // 出发地点（简体）
    arrStationName: state.trainEndCity.nameCn, // 到达地点（简体）
    // depStation: '', // 出发地点三字码
    // arrStation: '', // 到达地点三字码
    depDate: state.trainGoDate.day_s, // 出发日期 2021-10-14
    GDCFlag: state.isHighSpeed ? 1 : 0, // 是否只支持高铁动车(true支持 false不支持)
  }

  let host = 'www.t.hopegoo.com'
  if (/www\.hopegoo\.com/.test(location.host)) {
    host = 'www.hopegoo.com'
  }
  const targetUrl = jumpToRefidUrl(`https://${host}/trainintlpc/hopegooPc/#/list?${$utils.jsonToQuery(queryParam)}`)
  location.href = targetUrl
}

watch(trainHistoryCity, (newValue) => {
  utils.storage.trainHistoryCity = newValue
  utils.setStorage()
}, { deep: true })

// 初始化页面
function init() {
  setTimeout(() => {
    if (process.client) {
      trainStore.querySaleDays()
      trainStore.getTrainHomeConfig()
      trainStore.getFaqInfo()
      trainStore.getHotCityList()

      if (utils.storage?.fromStation && utils.storage?.toStation) {
        cityFunc.fillHistoryInfo(utils.storage)
      }

      if (utils.storage?.trainHistoryCity?.length) {
        cityFunc.setTrainHistoryCity()
      }

      if (dateFunc.checkDateIsInvalid(utils.storage?.trainGoDate)) {
        dateFunc.setTrainGoDate(today, false)
      }
      else {
        dateFunc.setTrainGoDate(utils.storage.trainGoDate, false)
      }

      document.body.addEventListener('click', () => {
        closeAll()
      }, false)
    }
  })
}

onMounted(() => { init() })
</script>

<template>
  <div class="train-content">
    <div class="train-wrapper">
      <form>
        <!-- 出发地、目的地 -->
        <div class="query-station" @click.stop>
          <ul>
            <li class="from-box" :class="state.isFromLine ? 'is-line' : ''">
              <div class="input-box">
                <input
                  id="fromCity" v-model="state.fromInfo" type="text" class="truncate new-city" :class="state.isAnimate ? 'to_right' : ''"
                  autocomplete="off" ondblclick="this.select()"
                  :placeholder="state.b_train_from"
                  @focus="cityFunc.focus(1, $event)"
                  @click="cityFunc.click(1, $event)"
                  @input="cityFunc.input(1, $event)"
                  @blur="cityFunc.blur(1, $event)"
                >
              </div>
            </li>
            <li class="btn-change" @click="cityFunc.cityChange" />
            <li class="to-box" :class="state.isToLine ? 'is-line' : ''">
              <div class="input-box">
                <input
                  id="toCity" ref="refToCity" v-model="state.toInfo" type="text" class="truncate new-city" :class="state.isAnimate ? 'to_left' : ''"
                  autocomplete="off" ondblclick="this.select()"
                  :placeholder="state.b_train_to"
                  @focus="cityFunc.focus(2, $event)"
                  @click="cityFunc.click(2, $event)"
                  @input="cityFunc.input(2, $event)"
                  @blur="cityFunc.blur(2, $event)"
                >
              </div>
            </li>
          </ul>
          <p v-if="state.QerrorMsg" class="error-msg">
            <span>{{ state.QerrorMsg }}</span>
          </p>
        </div>
        <!-- 出发时间、结束时间 -->
        <div class="query-time" @click.stop>
          <ul>
            <li class="godate-box" :class="state.isGoDateLine ? 'is-line' : ''">
              <div class="input-box">
                <input
                  id="goDate" type="text" class="new-city" autocomplete="off" :placeholder="state.b_train_departure"
                  :value="state.startDate"
                  readonly
                  @focus="dateFunc.clickCalendar"
                >
              </div>
            </li>
          </ul>
          <p v-if="state.DerrorMsg" class="error-msg">
            <span>{{ state.DerrorMsg }}</span>
          </p>
          <TrainCalendar ref="refTrainCalendar" @confirm="emitConfirmDate" />
        </div>
        <!-- 搜索按钮 -->
        <div class="btn-search" data-btn-search-type="train" @click="handleSearch">
          {{ t('home.s_train_search') }}
        </div>

        <!-- 历史搜索 -->
        <div v-show="trainHistoryCity && trainHistoryCity.length" id="train_recent_search">
          <ul>
            <li v-for="(info, index) in trainHistoryCity" :key="index">
              <p data-type="3" @click="cityFunc.handleSelectHistory(info)">
                {{ info.fromStation.nameX }} - {{ info.toStation.nameX }}
              </p>
              <span class="close_btn" @click="cityFunc.handleRemoveHistory(index)" />
            </li>
          </ul>
        </div>
        <!-- 高铁 -->
        <div class="high_speed">
          <span :class="{ selected: state.isHighSpeed }" @click="switchHighSpeed">{{ t('home.f_train_high_speed') }}</span>
        </div>
      </form>
    </div>
    <!-- 城市列表弹框 -->
    <div @click.stop>
      <TrainPopularCity
        ref="refPopularCity"
        :hot-city-list="trainStore.hotCityList"
        @confirm="emitConfirmCity"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.train-content {
  position: relative;
  z-index: 95;
  padding: 0 30px;
}

.train-wrapper {
  position: relative
}

.train-wrapper form li>div {
  position: relative;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background:#F7F8FB;

}

.train-wrapper form li>div:before {
  content: '';
  width: 0;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -ms-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  z-index: 11
}

.train-wrapper form .from-box>div:before {
  -webkit-border-radius: 0 0 0 6px;
  -moz-border-radius: 0 0 0 6px;
  border-radius: 0 0 0 6px
}

.flight-wrapper form .godate-box.line_radius>div:before {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px
}
.train-wrapper form li.is-line>div:before {
  left: 0;
  width: 100%;
  background: var(--Brand-Green)
}

.train-wrapper .query-station {
  position: relative;
  display: inline-block;
  width: 670px;
  height: 70px;
  background: #fff;
  border-radius: 8px;
  background: #F7F8FB;
}

.train-wrapper .query-station .from-box,.train-wrapper .query-station .to-box {
  display: inline-block;
  width: 327px;
}

.train-wrapper .query-station .btn-change {
  vertical-align: top;
  display: inline-block;
  width: 16px;
  height: 70px;
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/icon_switch.png) no-repeat center center;
  -webkit-background-size: 16px 13px;
  background-size: 16px 13px;
  cursor: pointer;
  // background: #F7F8FB;
}

.train-wrapper .query-station .btn-change:hover {
  background: url(//pic5.hopegoocdn.com/i/ori/1xjmOKy5X1u.png) no-repeat center center;
  -webkit-background-size: 16px 13px;
  background-size: 16px 13px
}

.train-wrapper .input-box .new-city {
  margin: 0;
  padding: 0 14px;
  width: 100%;
  height: 40px;
  overflow: hidden;
  line-height: 40px;
  text-align: left;
  font-size: 16px;
  color: #333;
  outline: 0;
  border: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  text-shadow: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  font-weight: 700;
  background: #F7F8FB;
}

.train-wrapper .to_right {
  opacity: 0;
  transform: translate3d(110%,0,0)
}

.train-wrapper .to_left {
  opacity: 0;
  transform: translate3d(-110%,0,0)
}

.train-wrapper .query-time {
  position: relative;
  display: inline-block;
  width: 300px;
  height: 70px;
  background: #fff;
  margin-left: 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 8px
}

.train-wrapper .query-time .godate-box {
  display: inline-block;
  width: 100%;
  height: 70px
}

.train-wrapper .btn-search {
  vertical-align: top;
  display: inline-block;
  margin-left: 10px;
  width: 140px;
  height: 70px;
  line-height: 70px;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  background: var(--Brand-Green);
  cursor: pointer;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  font-weight: 600;
}

.train-wrapper .error-msg {
  position: absolute;
  width: 100%;
  bottom: -33px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  color: #ff5346;
  font-weight: 500;
  background-color: #fffcec;
  z-index: 9;
}

.train-wrapper .error-msg>span {
  display: inline-block;
  margin: 0 12px
}

.search-city-wrapper {
  display: none;
  z-index: 100;
  position: absolute;
  top: 64px;
  padding: 20px 0;
  width: 420px;
  box-sizing: border-box;
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px
}

.search-city-wrapper .search_click.list_hover {
  color: var(--Brand-Green);
  cursor: pointer
}

.search-city-wrapper .search_city {
  position: relative;
  margin-bottom: 11px;
  padding: 0 30px 0 44px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  line-height: 30px
}

.search-city-wrapper .search_city:before {
  content: '';
  position: absolute;
  left: 20px;
  width: 14px;
  height: 30px
}

.search-city-wrapper .search_country {
  margin-left: 20px;
  line-height: 30px;
  font-size: 12px;
  color: #999
}

.search-city-wrapper .search_air {
  position: relative;
  line-height: 30px;
  padding-left: 62px;
  padding-right: 20px;
  font-size: 14px;
  color: #666
}

.search-city-wrapper .search_air:before {
  content: '';
  position: absolute;
  left: 40px;
  width: 12px;
  height: 30px;
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/icon_city_zhe.png) no-repeat center center;
  background-size: 12px 7px
}

.search-city-wrapper .search_near {
  padding-left: 44px;
  line-height: 20px;
  margin-bottom: 5px
}

.search-city-wrapper .highlight {
  color: var(--Brand-Green)
}

.btm_10 {
  margin-bottom: 10px
}

.near1 {
  vertical-align: top;
  display: inline-block;
  margin-top: 7px;
  width: 29px;
  height: 14px;
  background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/label_search_near.png) no-repeat center;
  background-size: 29px 14px
}

.near2 {
  display: inline-block;
  width: 306px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  font-size: 14px;
  color: #666;
  position: relative
}

.near2 .showtxt {
  display: inline-block;
  width: 260px
}

.near2 .distance {
  position: absolute;
  right: 0;
  top: 4px;
  color: #ccc;
  font-size: 12px
}

.search-city-wrapper .no_city {
  padding-left: 20px;
  line-height: 16px;
  font-size: 16px;
  color: #666
}

.popular-city-wrapper {
  display: none;
  z-index: 99;
  position: absolute;
  top: 64px;
  width: 580px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 14px
}

.popular-city-wrapper ul>li>div {
  padding: 0 10px
}

.popular-city-wrapper .area-title {
  margin-bottom: 18px;
  padding: 0 20px;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  font-weight: 700;
  color: #333;
  background: #f4f6f9
}

.popular-city-wrapper ul>li:first-child .area-title {
  border-radius: 6px 6px 0 0
}

.popular-city-wrapper .mNotice-normal {
  float: left;
  cursor: pointer;
  display: block;
  height: 26px;
  line-height: 26px;
  padding: 0 0 12px 0;
  margin: -3px 20px 0 0;
  width: 120px;
  color: #666;
  white-space: nowrap;
  overflow: hidden
}

.popular-city-wrapper .mNotice-normal em {
  display: inline-block;
  padding: 0 10px;
  height: 26px;
  line-height: 26px;
  max-width: 100%;
  font-style: normal;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}

.popular-city-wrapper .mNotice-normal em:hover {
  color: var(--Brand-Green)
}

.popular-city-wrapper em.current {
  font-weight: 500;
  color: var(--Brand-Green);
  box-sizing: border-box
}

.train-wrapper .high_speed span{
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    display: inline-block;
    margin-top: 8px;
    font-size:16px;
    color:#333;
    padding-left:28px;
    background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/icon_check_box.svg) no-repeat left center;
    cursor: pointer;
    -webkit-transition: opacity .5s;
    -moz-transition: opacity .5s;
    -ms-transition: opacity .5s;
    -o-transition: opacity .5s;
    transition:opacity .5s;
}
.train-wrapper .high_speed span.selected{
    background: url(//file.hopegoocdn.com/hopegoo/web-pc/images/homepage/img/icon_checked.svg) no-repeat left center;
}

#train_recent_search{
    width: 580px;
    height: 28px;
    margin-top: 12px;
    overflow: hidden;
}
#train_recent_search ul{
    font-size: 0;
}
#train_recent_search li{
    display: inline-block;
    height: 28px;
    line-height: 28px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #F7F8FB;
    margin-right: 6px;
    cursor: pointer;
}
#train_recent_search li p{
    display: inline-block;
    vertical-align: text-bottom;
    height: 28px;
    padding-left: 8px;
    padding-right: 6px;
    font-size: 12px;
    color: #000;
    position: relative;
}
#train_recent_search li p:after{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    display: inline-block;
    width: 1px;
    height: 12px;
    background: rgba(0 ,0,0,.3);
    margin-left: 6px;
    /* margin-bottom: -1px; */
}
#train_recent_search .close_btn{
    display: inline-block;
    width: 24px;
    height: 28px;
    background: url("//file.hopegoocdn.com/hopegoo/web-pc/images/icon_close1.svg") no-repeat center;
    -webkit-background-size: 8px;
    background-size: 8px;
}
</style>
