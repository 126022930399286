<script setup>
import { useTrainStore } from '~/store/train'

const emits = defineEmits(['confirm'])
const today = dayjs().formatDate()
const trainStore = useTrainStore()

const data = reactive({
  weeks: trainStore.isZh ? ['週日', '週一', '週二', '週三', '週四', '週五', '週六'] : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  today: '',
  goDate: '',

  startDate: '',
  endDate: '',

  startMonth: '',
  endMonth: '',
  currentMonth: '',
  months: [],

  showTips: false,
  showPop: false,
})

function setMonths() {
  // 两个月的日历
  data.months = [
    getMonth(data.currentMonth),
    getMonth(dayjs(data.currentMonth).add(1, 'month').formatDate()),
  ]
}
function getMonth(date) {
  date = dayjs(dayjs(date).formatDate())
  return {
    title: trainStore.isZh ? date.locale('zh-hk').format('MMM YYYY') : date.format('MMM YYYY'),
    days: getDays(date),
  }
}
function getDays(date) {
  const days = []

  const start = date.date(1).day() % 7 // 每月一号是周几
  for (let i = 0; i < start; i++) {
    days.push({ empty: 1, key: `key_0_${i}` })
  }

  const nums = date.date(1).add(1, 'month').date(0).date() // 每月最后一天是几号 (有几天)
  for (let i = 1; i <= nums; i++) {
    const d = date.date(i)
    const n = {}
    n.year = d.year()
    n.month = d.month() + 1
    n.day = i
    n.week = d.day()
    n.value = d.formatDate()
    n.key = `key_1_${n.value}`
    n.disabled = d.isBefore(data.today) || d.isAfter(data.endDate)
    n.enable = !n.disabled
    n.isWeekEnd = n.week === 6 || n.week === 0
    n.isToday = n.value === data.today
    n.d = d

    days.push(n)
  }
  return days
}
function getClass(n) {
  if (!n.value) {
    return
  }

  const d = n.d
  n.isGodate = data.goDate && n.value === data.goDate

  return {
    disabled: n.disabled,
    enable: n.enable,
    select: n.isGodate,
    yellow: !n.disabled && (n.isWeekEnd || n.isToday),
    scope: false,
  }
}

function getBottomTips() {
  return ''
}
function getDateObj(date) {
  return {
    value: dayjs(date).formatDate(),
  }
}

// 点击日期
function clickDate(n) {
  if (n.disabled) {
    return
  }

  data.goDate = n.value
  data.showTips = false

  emits('confirm', data.goDate)
  close()
}
// 上个月
function prevMonth() {
  if (!dayjs(data.currentMonth).isAfter(data.startMonth)) { return }
  data.currentMonth = dayjs(data.currentMonth).add(-1, 'month').formatDate()
  setMonths()
}
// 下个月
function nextMonth() {
  if (!dayjs(data.currentMonth).isBefore(data.endMonth)) { return }
  data.currentMonth = dayjs(data.currentMonth).add(1, 'month').formatDate()
  setMonths()
}

function init(date) {
  const preDays = trainStore.saleDaysConfig.preDays
  const goDate = dayjs(date || '').formatDate()

  const startDate = today // 日历开始日期
  const endDate = dayjs(startDate).add(preDays - 1, 'day').formatDate() // 火车票预售 15 天

  const startMonth = dayjs(startDate).date(1).formatDate()
  const endMonth = dayjs(endDate).date(1).add(-1, 'month').formatDate()
  let currentMonth = dayjs(goDate).date(1).formatDate()

  if ((endMonth === startMonth || dayjs(endMonth).isAfter(startMonth)) && dayjs(currentMonth).isAfter(endMonth)) {
    currentMonth = dayjs(endMonth).formatDate()
  }

  data.today = today
  data.goDate = goDate

  data.startDate = startDate
  data.endDate = endDate

  data.startMonth = startMonth
  data.endMonth = endMonth
  data.currentMonth = currentMonth

  setMonths()
}

function show(date) {
  init(date)

  nextTick(() => {
    data.showPop = true
  })
}
function close() {
  data.showPop = false
}

defineExpose({
  show,
  close,
})
</script>

<template>
  <div v-if="data.showPop" class="cal_box" @click.stop>
    <div
      class="cal_btn cal_btn_prev sprite_img"
      :class="{ cal_btn_disabled: !dayjs(data.currentMonth).isAfter(data.startMonth) }" @click="prevMonth"
    />
    <div
      class="cal_btn cal_btn_next sprite_img"
      :class="{ cal_btn_disabled: !dayjs(data.currentMonth).isBefore(data.endMonth) }" @click="nextMonth"
    />
    <!-- 日期 -->
    <div v-for="month in data.months" :key="month.title" class="month">
      <div class="month_title">
        {{ month.title }}
      </div>
      <div class="week_box">
        <div v-for="n in data.weeks" :key="n" class="week">
          {{ n }}
        </div>
      </div>
      <div class="days">
        <div
          v-for="n in month.days" :key="n.key" class="day_box" :class="getClass(n)"
          @click="clickDate(n)"
        >
          <div class="day" :data-val="n.value" :data-enable="n.enable">
            {{ n.day }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="data.showTips" class="cal_tips">
      {{ t('home.s_hotel_date_s_tip1') }}
    </div>
    <div class="cal_bottom">
      <!-- <div class="cal_b_t1">
        {{ getBottomTips() }}
      </div>
      <div class="cal_b_t2">
        {{ t('home.s_hotel_date_s_tip2') }}
      </div> -->
    </div>
  </div>
</template>

<style scoped lang="scss">
.cal_box {
  position: absolute;
  left: 0;
  top: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 740px;
  // height: 450px;
  margin-top: 10px;
  padding: 0 20px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  transform: translateX(-39%);
}

.month {
  width: 336px;
}

.month_title {
  position: relative;
  flex: none;
  width: 100%;
  line-height: 50px;
  text-align: center;
  padding-top: 10px;
  font-size: 18px;
}

.cal_btn {
  position: absolute;
  z-index: 10;
  top: 20px;
  width: 20px;
  height: 20px;
  background-position: 0px -300px;
  cursor: pointer;
}

.cal_btn_prev {
  left: 30px;
  transform: rotate(180deg);
}

.cal_btn_next {
  right: 30px;
}
.cal_btn_disabled {
  background-position: 0px -262px;
  cursor: not-allowed;
}

.week_box {
  flex: none;
  display: flex;
  width: 100%;
  height: 20px;
}

.week {
  width: 48px;
  line-height: 20px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
}

.days {
  display: flex;
  flex-wrap: wrap;
}

.day_box {
  flex: none;
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  font-weight: 600;
  font-size: 16px;
}

.scope {
  bbackground-color: var(--Selected-Green);
}

.scope_1 {
  border-radius: 4px 0 0 4px;
}

.scope_2 {
  border-radius: 0 4px 4px 0;
}

.disabled {
  color: #ccc;
}

.enable {
  cursor: pointer;
}

.yellow {
  color: var(--Brand-Green);
}

.select .day {
  color: white;
  background: var(--Brand-Green);
  border-radius: 4px;
}

.cal_tips {
  flex: none;
  width: 100%;
  margin-top: 20px;
  padding-right: 10px;
  text-align: right;
  font-size: 12px;
  color: #cf2828;
}
.cal_bottom {
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 70px;
  text-align: center;
  margin-top: 10px;
  border-top: solid 1px #E4E4E4;
}
.cal_b_t1 {
  font-weight: 600;
  font-size: 14px;
}
.cal_b_t2 {
  font-weight: 400;
  font-size: 12px;
  color: #999;
  // margin-top: 2px;
}

.sprite_img {
    background-image: url('https://pic5.hopegoocdn.com/i/ori/1xjEJpOQwk8.png') !important;
  }
</style>
