<script setup lang="ts">
import { useTrainStore } from '~/store/train'

const trainStore: any = useTrainStore()
const title = computed(() => {
  return trainStore.config?.seoTicketsName || ''
})
const list = computed(() => {
  return trainStore.config?.seoTicketsList || []
})
</script>

<template>
  <div id="how-to-buy" class="how-to-buy">
    <div class="buy-Title">
      {{ title }}
    </div>
    <div class="buy-back">
      <ul class="buy-content">
        <li v-for="(item, index) in list" :key="index" class="buy-item">
          <div class="box">
            <div class="box-info">
              <img :src="item.iconUrl">
              <h4 class="buy-title">
                {{ item.title }}
              </h4>
              <p class="buy-subTitle">
                {{ item.subTitle }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
/*How to buy*/
.how-to-buy{
    padding-bottom: 60px;
}
.how-to-buy .buy-Title{
    width: 100%;
    height: 44px;
    font-size: 44px;
    font-family: 'Segoe UI';
    font-weight: 600;
    text-align: center;
    color: #000;
    margin-top: 60px;
    margin-bottom: 60px;
}
.buy-back{
    text-align: center;
}
.buy-content{
    /* padding: 30px 0; */
    width: 1190px;
    margin: 0 auto;
    display: flex;
    text-align: center;
}
.buy-item{
    flex: 1;
    text-align: center;
}
.buy-item .box{
    display: flex;
    text-align: center;
}
.buy-item .box .box-info{
    width: 100%;
    text-align: center;
}
.buy-item .box img{
    margin: 0 auto;
    width: 204px;
    height: 143px;
}

.buy-item .box h4{
    font-size: 18px;
    color: #333;
    font-weight: bold;
    margin-top: 40px;
    padding-bottom: 18px;
    text-align: center;
}
.buy-item .box p{
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #888;
    text-align: center;
}
</style>
