<script setup lang="ts">
import { httpGet } from '@/utils/http'

interface Prop {
  hotCityList: CityItem[]
}
const props = defineProps<Prop>()
const emits = defineEmits(['confirm'])

const { t } = useLang()
const { locale } = useI18n()
const serverTimeDiff = 0 // 服务器和本地的时间差

enum Entrance {
  From = 1,
  To = 2,
}
interface CityItem {
  nameX: string
  nameCn: string
  showStr?: string
  cityId?: string
}
interface PopularState {
  [Key: string]: any
}
interface SearchState {
  trainSearchCitys: CityItem[]
  [Key: string]: any
}

const entrance = ref(0)
const popPosition = ref('30px')
const popularState: PopularState = reactive({
  curCity: {},
  showPopularCity: false,
})
const searchState: SearchState = reactive({
  inputValue: '',
  showSearchCity: false,
  trainSearchCitys: [],
  inputCity: '',
})

/**
 * 类型是否Date类型
 * @param {*} date 要检测的值
 */
function typeOfDate(date: Date) {
  return Object.prototype.toString.call(date) === '[object Date]'
}

// 设置日历可选结束日期
function TrainEndTime() {
  const date = new Date(Date.now() + serverTimeDiff)
  date.setHours(0, 0, 0, 0)
  date.setDate(date.getDate() + 29) // 30天
  return date
}
/**
 * @method 选择城市
 * @param city
 * @param type //类型：1 搜索结果，2 热门城市 3.历史搜索
 */
function selectCity(city: any, type: string) {
  // if (city && city.clickable === 0) { return } // 这里要注意，不可点

  if (!city) {
    return
  }

  emits('confirm', city)
  popularState.showPopularCity = false
  searchState.showSearchCity = false
}

function setPosition(entranceType: number) {
  entrance.value = entranceType

  if (entranceType === Entrance.To) {
    popPosition.value = '377px'
  }
  else {
    popPosition.value = '30px'
  }
}

function close() {
  popularState.showPopularCity = false
  searchState.showSearchCity = false
}
// 热门城市
const popularCity = {
  initHistory() {},
  open(entranceType: number, city: CityItem) {
    popularState.curCity = city

    setPosition(entranceType)
    searchState.showSearchCity = false
    popularState.showPopularCity = true
  },
}

// 搜索城市
const searchCity = {
  searchSubmit(entranceType: number, inputValue: string) {
    searchState.inputValue = inputValue

    setPosition(entranceType)
    popularState.showPopularCity = false

    searchState.showSearchCity = true
    this.querySearchCityList(inputValue)
  },
  async querySearchCityList(keyWord: string) {
    try {
      const res = await httpGet('/pcapi/train/search', { keyWord })
      if (res.code === 0) {
        searchState.trainSearchCitys = (res.data?.StationList || []).map((city: any) => {
          return {
            nameX: city.Xname,
            nameCn: city.Name,
            pinyin: city.QPY,
            cityId: city.ID,
            showStr: this.replaceSeach(`${city.Xname} (${city.Name})`),
          }
        })
      }
    }
    catch (error) {}
  },
  // 替换搜索的信息 标绿
  replaceSeach(txt: string) {
    if (!searchState.inputValue || !txt) { return txt }

    const arr = [...searchState.inputValue]
    let reg: any = ''
    arr.forEach((n) => {
      reg += `(${n})?`
    })
    try {
      reg = new RegExp(reg, 'gi')
    }
    catch (e) {}

    txt = txt.replace(reg, (n) => {
      return n ? `<span style=\'color:00C868;\'>${n}</span>` : ''
    })
    txt = txt.replace(/(无机场)/, '<span style=\'color:#999;\'>$1</span>')
    return txt
  },
}

defineExpose({
  popularState,
  popularCity,
  searchState,
  searchCity,
  methods: {
    close,
  },
})
</script>

<template>
  <!-- 热门城市 -->
  <div v-show="popularState.showPopularCity" id="train_popular_city" :style="{ left: popPosition }">
    <p class="area_title">
      {{ t('home.s_train_hot_title') }}
    </p>
    <div v-if="props.hotCityList" class="city_box">
      <!-- 类型：1 搜索结果，2 热门城市 3.历史搜索 -->
      <p
        v-for="hotCityItem in props.hotCityList"
        :key="hotCityItem.nameX"
        class="city_item" :class="{ current: popularState.curCity && popularState.curCity.nameX === hotCityItem.nameX }"
        :title="`${hotCityItem.nameX}(${hotCityItem.nameCn})`"
        @click="selectCity(hotCityItem, '2')"
      >
        <span>{{ hotCityItem.nameX }} </span><span>({{ hotCityItem.nameCn }})</span>
      </p>
    </div>
  </div>

  <!-- 城市搜索框 -->
  <div v-show="searchState.showSearchCity" id="train_search_city" :style="{ left: popPosition }">
    <div class="search_list">
      <p v-if="searchState.trainSearchCitys.length === 0" class="no_result">
        {{ t('home.f_train_noresult') }}
      </p>
      <p
        v-for="searchCityItem in searchState.trainSearchCitys"
        :key="searchCityItem.nameX"
        class="search_city search_station text-esllip"
        @click="selectCity(searchCityItem, '1')"
        v-html="searchCityItem.showStr"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
/*热门城市*/
#train_popular_city{
  position: absolute;
  z-index: 99;
  top:74px;
  width: 580px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 14px;
  box-shadow: 0 0 10px 1px rgba(141, 143, 151, 0.2);
}
#train_popular_city .area_title{
    margin-bottom: 16px;
    padding: 0 20px;
    height: 36px;
    line-height: 36px;
    font-weight: bold;
    color: #333;
    background: #f4f6f9;
    -webkit-border-radius: 6px 6px 0 0;
    -moz-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
}
#train_popular_city .city_box{
    padding-left: 20px;
    padding-bottom: 20px;
    font-size: 0;
}
#train_popular_city .city_item{
    display: inline-block;
    width: 120px;
    margin-right: 20px;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    cursor: pointer;
}
#train_popular_city .city_item:hover{
    color: var(--Brand-Green);
}
#train_popular_city .city_item.current{
    color: var(--Brand-Green);
    font-weight: 500;
}

/*下拉搜素*/
#train_search_city{
    width: 283px;
    z-index: 100;
    position: absolute;
    top:74px;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    box-shadow: 0 0 10px 1px rgba(84,95,141,.2);
}
#train_search_city .search_list{
    padding: 10px 0;
}
#train_search_city .search_list .search_city{
    font-size: 14px;
    color: #666;
    line-height: 36px;
    padding: 0 20px;
}
#train_search_city .search_list .search_city .highlight {
    color: var(--Brand-Green);
}
#train_search_city .search_list .search_city:hover{
    color: var(--Brand-Green);
}
#train_search_city .no_result{
    padding-left: 20px;
    line-height: 16px;
    font-size: 14px;
    color: #666;
}
</style>
