<script setup lang="ts">
import { useTrainStore } from '~/store/train'

const { t } = useLang()
const { locale } = useI18n()
const trainStore: any = useTrainStore()

const faqList = computed(() => {
  return trainStore.trainFaqData || []
})
const faqImgList = computed(() => {
  return trainStore.config?.seoImageList || []
})
</script>

<template>
  <!-- new gonglue  start -->
  <div id="strategy" class="strategy">
    <div class="strategy-title">
      {{ t('home.s_train_faq_title') }}
    </div>
    <div class="strategy-subtitle">
      {{ t('home.s_train_faq_subtitle') }}
    </div>
    <ul v-if="faqList && faqList.length" class="strategy-content">
      <li
        v-for="(item, index) in faqList"
        :key="index"
        class="strategy-item"
      >
        <p class="info">
          {{ index + 1 }}. {{ item.questionTitle }}
        </p>
        <p class="sub-info" v-html="item.answerContent" />
      </li>
    </ul>
    <div class="ticket-image">
      <div class="ticket-image-back">
        <ul class="ticket-image-content">
          <li v-for="(imgItem, index) in faqImgList" :key="index" class="ticket-image-item">
            <div class="box">
              <div class="box-info">
                <h4 class="buy-title">
                  {{ imgItem.title }}
                </h4>
                <img :src="imgItem.imageUrl">
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
/* 攻略 */
.strategy{
    /* max-width: 1920px; */
    width: 1188px;
    /* height: 452px; */
    margin: 0 auto;
    text-align: center;
}
.strategy .strategy-title{
    width: 100%;
    height: 44px;
    font-size: 44px;
    font-family:Segoe UI;
    font-weight:600;
    color:rgba(51,51,51,1);
    margin-top: 55px;
}
.strategy .strategy-subtitle{
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    font-family:Segoe UI;
    font-weight:400;
    color:rgba(153,153,153,1);
    text-align: left;
    margin-top: 42px;
}
.strategy .strategy-content{
    width: 100%;
    text-align: left;
    margin: 0 auto;
}
.strategy .info{
    font-size:16px;
    font-family:Segoe UI;
    font-weight:600;
    color:rgba(51,51,51,1);
    line-height:30px;
    margin-top: 33px;
}
.strategy .sub-info{
    font-size:14px;
    font-family:Segoe UI;
    font-weight:400;
    color:rgba(153,153,153,1);
    line-height:20px;
    margin-top: 1px;
}
.strategy .ticket-image{
    text-align: center;
    margin-bottom: 57px;
}
.strategy .ticket-image-content{
    /* padding: 30px 0; */
    width: 1190px;
    margin: 0 auto;
    display: flex;
    text-align: center;

}
.strategy .ticket-image-item{
    flex: 1;
    text-align: center;
}
.strategy .ticket-image-item .box{
    display: flex;
    text-align: center;
}
.strategy .ticket-image-item .box .box-info{
    width: 100%;
    text-align: center;
}
.strategy .ticket-image-item .box img{
    width: 525px;
    height: 268px;
}

.strategy .ticket-image-item .box h4{
    font-size: 14px;
    color: #333;
    font-weight: bold;
    margin-top: 40px;
    padding-bottom: 31px;
    font-weight:400;
    text-align: center;
}
.strategy .strategy-more{
    font-size:14px;
    font-family:Segoe UI;
    font-weight:400;
    color:rgba(136,136,136,1);
    text-align: right;
    margin-bottom: 40px;
    cursor: pointer;
}
.strategy .strategy-more a{
    color: #00a0ff;
    text-decoration: underline;
}
</style>
