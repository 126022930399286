import { defineStore } from 'pinia'
import { http, httpPost, request } from '@/utils/http'

const goDate = dayjs().formatDate()
function getDateObj(date: string) {
  return {
    // title: dayjs(date).format('ddd, MMM DD'),
    value: dayjs(date).formatDate(),
  }
}
const BuyDays = 15 // 购票最大提前天数

export const useTrainStore = defineStore('trainStore', {
  state: () => {
    const { locale } = useI18n()
    const lang = (locale.value || '').split('-')[0]
    const isZh = lang === 'zh'
    const isEn = lang === 'en'
    const isJa = lang === 'ja'

    return {
      isZh,
      isEn,
      isJa,
      config: {},
      trainFaqData: [],
      hotCityList: [],
      saleDaysConfig: {
        preDays: BuyDays,
        grabOrderMaxDay: 0,
      },

      /**
       * 出发日期
       */
      goDate: getDateObj(goDate),
    }
  },
  getters: {
  },
  actions: {
    async getTrainHomeConfig() {
      try {
        const res = await httpPost('/pcapi/train/home', {})
        if (res?.code === 0) {
          this.config = res.data
        }
      }
      catch (error) { }
    },
    async getFaqInfo() {
      try {
        const res = await http({
          url: '/sea_turtle_api/helpCenter/trainQaList',
          baseURL: '/sea_shell',
          method: 'POST',
        })
        if (res?.status === 200) {
          this.trainFaqData = res.body || {}
        }
      }
      catch (error) { }
    },
    async getHotCityList() {
      try {
        const res: any = await request({
          url: '/sea_shell/sea_octopus_api/pub/train/getHotCity',
          method: 'POST',
          data: {},
        })

        this.hotCityList = res.hotCityList
      }
      catch (error) {
        console.log('error', error)
      }
    },

    async querySaleDays() {
      try {
        const res = await httpGet(`/intlfebff/querySaleDays?traceId=T${+new Date()}`, {})
        if (res.data && res.data.code === 200) {
          const { preDays, grabOrderMaxDay } = res.data?.data?.preB || {}
          this.saleDaysConfig = {
            preDays: preDays ? Number(preDays) : BuyDays,
            grabOrderMaxDay: grabOrderMaxDay ? Number(grabOrderMaxDay) : 0,
          }
        }
      }
      catch (error) { }
    },
    setGoDate(date: string) {
      this.goDate = getDateObj(date)
    },
  },
})
